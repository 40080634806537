.news-details-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
margin-bottom: 5%;
    width: 100%;
}

.news-card {
    width: 90%; 
    max-width: 800px;
    background-color: #ffffff;
    margin-top: 3%;
    transition: transform 0.3s ease-in, box-shadow 0.3s ease-in-out;
}

.news-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
}

.news-card-img {

    height: auto; 
    
     max-width: 400px; 
    object-fit: cover; 
}

.news-card-body {
    padding: 20px; 
    text-align: center;
}

.news-title {
    font-size: 1.8rem; 
    font-weight: 700;
    margin-bottom: 15px;
    color: #333;
}

.news-description {
    font-size: 1rem; 
    line-height: 1.5;
    margin-bottom: 20px;
    color: #555;
}

.news-author,
.news-publish-date {
    font-size: 0.9rem; 
    color: #777;
    margin-bottom: 10px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
}

.spinner {
    width: 3rem;
    height: 3rem;
    border: 0.4rem solid rgba(0, 0, 0, 0.1);
    border-top-color: #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}


@media (max-width: 768px) {
    .news-card {
        width: 100%;
        border-radius: 10px;
    }

    .news-card-img {
        height: 200px; 
    }

    .news-card-body {
        padding: 15px;
    }

    .news-title {
        font-size: 1.5rem; 
    }

    .news-description {
        font-size: 0.9rem;
    }

    .news-author,
    .news-publish-date {
        font-size: 0.8rem;
    }
}


@media (max-width: 576px) {
    .news-card-body {
        padding: 10px;
    }

    .news-title {
        font-size: 1.2rem; 
    }

    .news-description {
        font-size: 0.8rem;
    }
}
