.song-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

.song-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
}

.song-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.song-card:hover {
  transform: scale(1.05);
}

.song-card h3,
.song-card h4,
.song-card p {
  margin: 10px 0;
}

.view-lyrics-button,
.remove-song-button {
  /* margin: 5px; */

  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
}

.view-lyrics-button {
  background-color: #007bff;
  color: white;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.view-lyrics-button:hover {
  background-color: #0056b3;
}

.remove-song-button {
  background-color: #dc3545;
  color: white;
  border: none;
  margin-top: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.remove-song-button:hover {
  background-color: #a71d2a;
}