/* General styling for the quiz container */
.quiz-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between; /* Center cards when wrapping */
}

.quiz-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: calc(33.333% - 20px); /* Three cards per row */
    min-width: 300px;
    /* Ensures cards don't get too small */
}

.options-container {
    display: flex;
    gap: 10px;
    /* Space between buttons */
}

.option-button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.option-button:hover {
    background-color: #f0f0f0;
    /* Light gray on hover */
}

.correct {
    background-color: green;
    /* Green for correct answers */
    color: white;
}

.incorrect {
    background-color: red;
    /* Red for incorrect answers */
    color: white;
}

/* Responsiveness */
@media (max-width: 1024px) {
    .quiz-card {
        width: calc(50% - 20px); /* Two cards per row on tablets */
    }

    .option-button {
        font-size: 1rem; /* Adjust font size for tablets */
    }
}

@media (max-width: 768px) {
    .quiz-card {
        width: 100%; /* One card per row on mobile */
    }

    .options-container {
        flex-direction: column; /* Stack the options vertically on smaller screens */
    }

    .option-button {
        width: 100%; /* Make buttons fill the width of the container */
        padding: 12px 0; /* Increase padding for easier tapping */
        font-size: 1.1rem; /* Larger text for mobile */
    }
}

@media (max-width: 480px) {
    .option-button {
        font-size: 1rem; /* Slightly smaller font size for very small screens */
        padding: 10px 0; /* Adjust padding for small screens */
    }
}
