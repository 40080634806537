.card-content {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-content:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.card-content img {
    object-fit: cover;
    height: 200px;
    border-radius: 10px;
}

.card-content .btn-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
}

/* .card-content .btn {
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
} */

/* .card-content .btn:hover {

    transform: scale(1.09);
} */

.card-content .btn.trending-btn {
    background-color: #28a745;
    color: white;
    margin-right: 2px;

}

.card-content .btn.recommended-btn {
    background-color: #17a2b8;
    color: white;
    margin-right: 2px;

}

.search-results-container .row {
    row-gap: 15px;
}

.card-content {
    margin-bottom: 15px;
    text-align: center;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination-container button {
    margin: 0 10px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}