* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* flora Editar csss */
.fr-fil {
  float: left !important; /* Corrected: Removed quotes around 'left' */
  display: inline-block !important;
}
.fr-fir {
  float: right !important;
  display: inline-block !important;
}
.fr-dib {
  display: block !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
/* Override for RTL */
.fr-rtl {
  direction: rtl !important;
  text-align: right !important;
}
/* Override for LTR */
.fr-ltr {
  direction: ltr !important;
  text-align: left !important;
}
[dir="rtl"] {
  text-align: right; /* Text aligns to the right for RTL languages */
  direction: rtl; /* Ensures correct typing direction */
}
[dir="ltr"] {
  text-align: left; /* Text aligns to the left for LTR languages */
  direction: ltr; /* Ensures correct typing direction */
}