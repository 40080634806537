.dark-input {
    background-color: #343a40;
    /* Dark background */
    color: #ffffff;
    /* Light text color */
    border: 1px solid #495057;
    /* Darker border */
}

.dark-input::placeholder {
    color: #adb5bd;
    /* Light grey placeholder */
}

.button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
}

.file-input {
    margin-left: 10px;
}

.artist-list {
    margin-bottom: 80px;
    list-style-type: none;
    padding: 0;
}

.artist-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    position: relative;
}

.artist-item a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.artist-image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-right: 15px;
}

.artist-details {
    display: flex;
    flex-direction: column;
}

.artist-name {
    font-size: 1.2rem;
    margin: 0;
}

.artist-followers {
    color: #666;
    margin: 0;
}