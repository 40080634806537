/* .add-hot-song-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.search-song-input {
    margin-bottom: 20px;
}

.search-results-container {
    margin-top: 20px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.empty-message {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    color: #888;
}

.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 90px;
}

.pagination-container button {
    margin: 0 10px;
}

.card-content {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 5px;
} */


.card-content {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 10px;
    overflow: hidden;
    padding: 15px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-content:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); 
  }
  
  .card-content img {
    object-fit: cover;
    height: 200px;
    border-radius: 10px;
  }
  
  .card-content .btn-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
  }
  
  .card-content .btn {
    padding: 5px 15px;
    font-size: 14px;
    border-radius: 20px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* .card-content .btn:hover {
    background-color: #007bff;
    transform: scale(1.05);
  } */
  
  .card-content .btn.trending-btn {
    background-color: #28a745;
    color: white;
    margin-right: 2px;

  }
  
  .card-content .btn.recommended-btn {
    background-color: #17a2b8;
    color: white;
    margin-right: 2px;

  }
  
  .search-results-container .row {
    row-gap: 15px;
  }
  
  .card-content {
    margin-bottom: 15px;
    text-align: center;
  }
  
  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination-container button {
    margin: 0 10px;
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }
  