.playlist-container {
  padding: 20px;
  /* background-color: #f5f5f5; */
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

.playlist-heading {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  margin-top: 20px;
}

.playlist-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
  justify-content: center;
}

.playlist-card {
  width: 250px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;
}

.playlist-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.playlist-card-content {
  padding: 15px;
}

.playlist-card-title {
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #333;
}

.playlist-card-description {
  font-size: 0.9rem;
  color: #777;
}

.playlist-card-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.playlist-button-group {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.no-playlists {
  text-align: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
