/* .container {
  margin: 50px auto;
  max-width: 1200px;
} */
.contricon{
  margin-top: 8%;
}
.main-heading {
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 2rem;
}

.search-input {
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
  gap: 20px;
  /* border: 1px solid #ddd;  */
  padding: 20px;
  /* background-color: #f9f9f9; */
  /* border-radius: 12px; */
}

.card-image {
  width: 100%;
  max-width: 250px;
  padding: 20px;
  border: 1px solid #ddd; 
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  text-align: center;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}                                                         

/* .card-song {
  width: 250px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  background-color: #fff;
} */

.card-image:hover {
  transform: scale(1.05); 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
}

.card-image img {
  width: 100%;
  height: 200px;
  border-radius: 8px; 
  object-fit: cover;
}



.card-image h3,
.card-image h4,
.card-image p {
  margin: 8px 0;
  font-size: 1rem;
}

.card-footer button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.add-button,
.delete-button {
  width: 90%;
  padding: 10px;
  margin: 10px auto;
  border-radius: 5px;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}



.show-button {
  background-color: #4caf50;
  color: #fff;
}

.add-button {
  background-color: #28a745;
  color: white;
}

.add-button:hover {
  background-color: #218838;
}

.delete-button {
  background-color: #f44336;
  color: #fff;
}

.delete-button:hover {
  background-color: #c82333;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 20px 0;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}



/* @media (max-width: 768px) {
  .card-container {
    padding: 10px;
  }

  .card-image {
    max-width: 200px;
  }

  .card-image h3,
  .card-image h4,
  .card-image p {
    font-size: 0.9rem;
  }
} */


/* @media (max-width: 480px) {
  .card-container {
    gap: 10px;
    padding: 5px;
  }

  .card-image {
    max-width: 180px;
  }

  .card-image h3,
  .card-image h4,
  .card-image p {
    font-size: 0.8rem;
  }

  .card-footer {
    flex-direction: column; 
    gap: 5px;
  }

  .card-footer button {
    font-size: 0.9rem;
  }
} */