/* General alignment fixes */
.row {
  margin: 0; /* Ensure no extra horizontal margin */
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align cards */
}

.col {
  padding: 0 15px; /* Maintain consistent spacing */
}

/* Card styles */
.song-card {
  /* width: 100%; Full width within its column */
  height: auto; /* Adjust height dynamically */
  margin: 0 auto; /* Center card horizontally */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.song-card img {
  width: 100%;
  max-height: 150px; /* Consistent image size */
  object-fit: cover;
}

.song-card-footer {

  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .song-card {
    margin-bottom: 15px;
  }
  .col {
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .song-card {
    margin-bottom: 10px;
  }
}
